.global{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 500px;
    background-color: white;
    user-select: none;
}

.parte1{
    color: rgb(1, 5, 100);
    font-size: 1.2rem;
    width: 50%;
    justify-content:center;
    justify-items: center;
    align-items: center;
    padding-left: 5%;
    user-select: none;
}

.parte1h3{
    width: fit-content;
    font-weight: bold;
    font-size: 2rem;
}

.parte2{
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(1, 5, 100);
    font-size: 1.2rem;
    object-position: center;
    width: 45%;
}

.parte2 img{
    max-width: 60%;
    height: 100%;
    object-fit: scale-down;
    padding-left: 5%;
}

@media screen and (max-width: 500px) {
    .global {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap-reverse;
        justify-content: center;
    }

    .parte1{
        width: 100%;
        font-size: 1.5rem;
        width: 90%;
        padding: 2% 2% 2% 5%;
    }

    .parte1 p{
        font-size: 1rem;
    }

    .parte1h3{
        text-align: center;
        width: 100%;
        font-weight: bold;
        font-size: 2rem;
        width: 90%;
        padding: 2% 2% 2% 5%;
        margin: 0;
    }

    .parte2{
        width: 100%;
        max-width: 100%;
        max-height: 100%;
        justify-content: center;
    }

    .global{
        height: auto;
        background-color: white;
    }

    .parte2 img{
        max-width: 90%;
        max-height: 90%;
        object-fit: scale-down;
        padding: 2% 2% 2% 2%;
    }
}

@media screen and (min-width: 501px) and (max-width: 960px){
    .global {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap-reverse;
        justify-content: center;
    }

    .parte1{
        width: 100%;
        font-size: 1.5rem;
        width: 90%;
        padding: 2% 2% 2% 5%;
    }

    .parte1 p{
        font-size: 1.2rem;
    }

    .parte1h3{
        text-align: center;
        width: 100%;
        font-weight: bold;
        font-size: 2rem;
        width: 90%;
        margin: 0;
        padding: 2% 2% 2% 5%;
    }

    .parte2{
        width: 100%;
        max-width: 100%;
        max-height: 100%;
        justify-content: center;
    }

    .global{
        height: auto;
        background-color: white;
    }

    .parte2 img{
        max-width: 90%;
        max-height: 90%;
        object-fit: scale-down;
        padding: 2% 2% 2% 2%;
    }
}