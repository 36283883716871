.NavbarItems{
    background-color: rgb(230, 229, 229);
    /*background: linear-gradient(90deg, rgb(110, 94, 254) 0%, rgba(73,63,252,1) 100%);*/
    height: 80px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    font-size: 1.2rem;
    width: 100%;
    user-select: none;
    z-index: 1;
}

.dropdown{
    display: none;
}

.ulsubmenu{
    margin-top:25px;
    color: white;

}

.dropdown\:active{
    display:flex;
    flex-direction: column;
    flex-wrap: nowrap;
    background-color: rgb(230, 229, 229);
    border-top: 3px ridge rgb(144, 51, 51);
    justify-content: center;
    text-align: left;
    list-style: none;
    height: 100%;
    width: 100%;
    padding-left: 0;
    margin-top: 40px;
    margin: 0;
}

.listasubm{
    display: flex;
    font-size: small;
    width: 100%;
    height: 30px;
    vertical-align: middle;
    text-align: left;
}

.listasubm:hover{
    color: rgb(144, 51, 51);
    background-color: lightgray;
}

.menuicon{
    display: none;
}

.navbarlogo{
    color: black;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
}

.navmenucont{
    height: 80px;
}

.navmenu{
    display: grid;
    grid-template-columns: repeat(6,auto);
    grid-gap: 20px;
    flex-direction: row;
    flex-wrap: nowrap;
    list-style: none;
    text-align: center;
    margin-left: 2rem;
}

.navmenuli{
    text-decoration: none;
    padding: 0.5rem;
    z-index: 1;
}

.navmenuli a{
    color: rgba(8, 12, 128);
    font-weight:normal;
    text-decoration: none;
    padding: 0.5rem;
}

.navmenuli a:hover{
    color: rgb(144, 51, 51);
}

.navlinks{
    color: white;
    text-decoration: none;
}

.fa-bars {
    color: rgb(144, 51, 51);
}

.fa-times {
    color: rgb(144, 51, 51);
}

.imagenlogo{
    height: 60px;
    justify-self: start;
    cursor: pointer;
}

.ulcont{
    background-color: rgb(230, 229, 229);
    /*background-color: rgba(8, 12, 128);*/
    height: 25px;
    border-color: rgba(8, 12, 128);
    border-spacing: 2px;
    border-top:none;
    border-bottom: 0.001vh ridge lightgray;
    border-right: none;
    border-left: none;
    justify-content: center;
}

.ulcont ul{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    margin: 0;
    padding: 0;
    justify-content:space-around;
}

.ulcont ul li{
    list-style: none;
    width: fit-content;
    padding-right: 30px;
}

.ulcont ul li a:hover{
    color: rgb(144, 51, 51);
}

.enlacepie{
    text-decoration: none;
    color: rgba(8, 12, 128);
    font-weight:normal;
    font-size:small;
  }

.active{
    border-bottom: 3px ridge rgb(144, 51, 51);
}

@media screen and (max-width: 960px){
    .NavbarItems{
        position: relative;
    }

    .navmenu{
        display: none;
        position: absolute;
        top: 0px;
        left: -10%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .navmenu li{
        justify-content: center;
        width: 100%;
    }

    .navmenu\:active{
        position: absolute;    
        display: flex;
        top: 61px;
        flex-direction: column;
        background-color: rgb(230, 229, 229);
        width: 50%;
        height: auto;
        left: 0%;
        opacity: 1;
        transition: all 0.5s ease;
        padding-left: 0px;
        z-index: 1;
    }

    .navmenu\:active li{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 40px;
        align-items: center;
    }

    .navmenu\:active li a{
        list-style: none;
        text-decoration: none;
        color: rgba(8, 12, 128);
        margin: 0;
        padding: 0;
        width: 100%;
    }

    .navlinks{
        display: table;
        text-align: left;
        text-decoration: none;
        padding-left: 10px;
        padding-right: 0;
        width: 97%;
        color: white;
        height: 40px;
    }

    .navmenu\:active li:hover{
        background-color: lightgray;
        color: rgb(144, 51, 51);
        border-radius: 0;
        width: 100%;
    }

    .navbarlogo{
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%, 10%);
    }

    .menuicon{
        display: block;
        position: absolute;
        top:0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times{
        color: rgb(144, 51, 51);
        font-size: 2rem;
    }

    .ulcont ul{
        width: 100%;
        justify-content: center;
    }

    .ulcont ul li{
        padding-right: 15px;
        padding-left: 0px;
    }
    
    .active{
        border: none;
    }

    .dropdown\:active{
        display: flex;
        position: absolute;
        flex-direction: row;
        flex-wrap: wrap;
        border-top: 3px ridge rgb(144, 51, 51);
        justify-content: flex-start;
        text-align: left;
        transform: translate(0px, -35px);
        height: 100%;
        width: 80%;
        margin-left: 2%;
        margin-top: 35px;
        margin: 0;
        z-index: 2;
    }
    
}

@media screen and (min-width: 961px) and (max-width: 1160px){
    .navmenu{
        display: grid;
        grid-template-columns: repeat(6, auto);
        grid-gap: 10px;
        list-style: none;
        text-align: center;
        width: 60vw;
        justify-content: center;
        margin-left: 2rem;
        font-size: 1.1rem;
    }

    .listasubm{
        height: auto;

    }
}