.cabecera{
    background-image: linear-gradient(to right, rgba(8, 12, 128, 0.9) 0, rgba(24, 111, 241, 0.6) 50%, rgba(8, 12, 128, 0.2) 100%), url(./images/fondocabecera.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    background-color: rgba(8, 12, 128);
    color: white;
    height: 250px;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.tituloh1{
    position: relative;
    text-align: center;
    color: white;
    top: 15%;
    font-size: 2rem;
}

.detallepro{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2% 2% 2% 5%;
}

.detallepro2{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2% 2% 2% 5%;
    background-color: lightgray;
}

.seccionew{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.detallenew{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 55%;
    color: rgba(8, 12, 128);
    padding: 2% 2% 2% 5%;
}

.divenlacesint{
    width: 40%;
    justify-items: center;
    border-style: solid black;
}

.enlacepie{
    text-decoration: none;
    color: rgba(8, 12, 128);
    font-weight: normal;
    font-size: medium;
  }

.gmapcontacto{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 2% 5% 2% 5%;
}

.gmap{
    width: 55%;
}

.direccion{
    color: rgba(8, 12, 128);
    font-weight: normal;
    font-size: medium;
}

.dcontacto{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.ulcont{
    width: 90%;
    padding: 10px;
    align-items: center;
}

.ulcont li{
    list-style: none;
}

.enlacesint{
    height: auto;
    width: 70%;
    color: rgba(8, 12, 128);
    float:left;
    margin: 5% 5% 5% 5%;
    border-style:outset;
    border-width: 1px;
    border-color: rgb(166, 166, 168);
}

.enlacesint li{
    list-style:square;
}

.enlacesint h3{
    display: flex;
    color: white;
    align-items:center;
    justify-content: space-around;
    background-color: rgba(8, 12, 128);
    height: 40px;
    margin: 0;
}

.enlacesint a{
    text-decoration: none;
    color: rgba(8, 12, 128);
    font-weight:normal;
}

.textopro{
    width: 50%;
    padding: 2% 2% 2% 2%;
    top: 50%;
    color: rgba(8, 12, 128);
/*    transform: translate(0%, 20%);*/
}

.textopro2{
    width: 50%;
    padding: 2% 2% 2% 5%;
    top: 50%;
    color: rgba(8, 12, 128);
/*    transform: translate(0%, 20%);*/
}

.textoprogen{
    top: 50%;
    left: 50%;
    width: 60%;
    color: rgba(8, 12, 128);
    transform: translate(0%, 0%);
}

.imagenpro{
    padding: 0 0 0 2%;
    width: 50%;
}

.imagenpro2{
    display: flex;
    width: 50%;
    align-items: center;
    padding: 0 0 0 2%;
    justify-items: center;
    justify-content: center;
}

.imagenpro img{
    max-width:70%;
    max-height: 100%;
    object-fit: scale-down;
    object-position: center;
}

.imagenpro2 img{
    max-width: 70%;
    max-height: 100%;
    object-fit: scale-down;
    object-position: center;
}

.imagenprogen{
    padding: 0 0 0 2%;
    width: 50%;
}

.imagenprogen img{
    max-width:90%;
    max-height: 100%;
    object-fit: scale-down;
    object-position: center;
}

.enlaces{
    text-decoration: none;
    color: rgba(8, 12, 128);;
  }

@media screen and (max-width: 600px) {
    .detallenew{
        width: 100%;
    }
    
    .divenlacesint{
        width: 100%;
    }

    .seccionew{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap-reverse;
    }

    .detallepro{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap-reverse;
        padding: 1% 5%;
    }

    .detallepro2{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 1% 5%;
    }
    
    .textopro{
        display: flex;
        flex-wrap: wrap;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(0%, 0%);
    }

    .textoprogen{
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(0%, 0%);
    }

    .textopro2{
        display: flex;
        flex-wrap: wrap;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(0%, 0%);
    }

    .imagenpro{
        padding: 0 0 0 0%;
        width: 100%;
    }

    .imagenprogen{
        padding: 0 0 0 0%;
        width: 100%;
    }
    
    .imagenpro img{
        max-width: 100%;
        max-height: 100%;
        object-fit: scale-down;
        object-position: center;
    }

    .imagenprogen img{
        max-width: 100%;
        max-height: 100%;
        object-fit: scale-down;
        object-position: center;
    }

    .imagenpro2 img{
        max-width: 100%;
        max-height: 100%;
        object-fit: scale-down;
        object-position: center;
    }

    .cabecera {
        height: 150px;
    }

    .enlacesint{
        height: auto;
        width: 90%;
        color: rgba(8, 12, 128);
        float:left;
        margin: 5% 5% 5% 5%;
        border-style:outset;
        border-width: 1px;
        border-color: rgb(166, 166, 168);
    }
}