body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  /*font-family: 'Source Sans Pro', sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#f2f4f8;
  color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.contenedor {
  display:flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to right, rgba(8, 12, 128, 0.9) 0, rgba(24, 111, 241, 0.6) 50%, rgba(8, 12, 128, 0.2) 100%), url("./businesspeople-finance.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  max-height: 100%;
  height: 600px;
  width: auto;
  font-size: 1.2rem;
  margin: 0 0 0 0;
}

.servicios{
  position: relative;
  align-items: center;
  justify-items: center;
}

.tituloh1 h1{
  color: white;
  margin: 10px 0 10px 0;
  font-size: 3.5rem;
  font-weight: bolder;
  user-select: none;
}

.tituloh2 h2{
  color: white;
  margin: 10px 0 10px 0;
  font-size: 2.2rem;
  user-select: none;
}

.boton{
  width: 220px;
  height: 40px;
  text-align: center;
  background-color: rgb(144, 51, 51);
  border-style: outset;
  border-width: 1px;
  font-weight: bold;
  color: lightgray;
  font-size: 15px;
  cursor: pointer;
  margin: 10px 0 10px 0;
  user-select: none;
}

.seccionultima{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: rgba(8, 12, 128);
  height: auto;
  width: auto;
  color: white;
  font-size: 12px;
}

.mapasitio{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 33.3%;
  margin: 2% 5% 2% 5%;
}

.mapasitio h3{
  font-size: 1.1rem;
}

.forma{
  width: 33.3%;
  margin: 2% 5% 2% 5%;
}

.contacto{
  width: 33.3%;
  margin: 2% 5% 2% 5%;
  font-size: 1.1rem;
}

.contacto h3{
  font-size: 1rem;
}

.cabecera{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: auto;
  width: auto;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  margin-bottom: 1%;
  text-decoration: none;
}

.imagenlogo{
  height: 60px;
}

.imagencontacto{
  width: 80px;
  height: 80px;
}

.enlacecabecera{
  text-decoration: none;
  color: rgba(8, 12, 128);
  font-weight: lighter;
  text-align: center;
}

.enlaces{
  text-decoration: none;
  color: lightgray;
}

.enlacecabecera:hover{
  font-weight: normal;
  border-bottom: solid rgba(121, 2, 2);
}

.enlacepie{
  text-decoration: none;
  color: lightgray;
  font-weight: normal;
  font-size: small;
}

.direccion{
  color: lightgray;
  font-weight: normal;
  font-size: small;
}

.enlacepie:hover{
  text-decoration: underline;
}

.menu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 1.2rem;
  font-weight: bolder;
  width: 500px;
  height: 30px;
  justify-content: space-between;
}

.lista{
  display:flex;
  flex-direction:row;
  padding-left: 0px;
  flex-wrap:nowrap;
  list-style: none;
  width: 40px;
  justify-content: space-between;
  z-index:1;
}

.botonrs{
  width: 80px;
  height: 80px;
  color: white;
  font-size: large;
}

.contenul{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ulcont{
  padding: 0;
  width: 70%;
  align-items: center;
}

.ulcont li{
  list-style: none;
}

.ulcontmap{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 0;
  width: 70%;
}

.ulcontmap li{
  list-style: none;
}

@media screen and (max-width: 500px) {
  .contenedor{
    background-size: cover;
    font-size: 1rem;
    height: 400px;
  }

  .cabecera{
    justify-content: center;
    vertical-align: middle;
  }
  
  .tituloh1 h1 {
    color: white;
    width: 100%;
    font-size: 2rem;
    font-weight: bolder;
    text-align: center;
    user-select: none;
  }

  .tituloh2 h2 {
    color: white;
    width: 100%;
    padding: 20px 0 20px 0;
    font-size: 1.5rem;
    text-align: center;
    user-select: none;
  }

  .boton{
    width: 180px;
    height: 30px;
    color: lightgray;
    font-size: 12px;
    cursor: pointer;
    user-select: none;
    align-items: center;
  }

  .titulo {
    font-size: 14px;
  }

  .seccionultima{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    background-color: rgba(8, 12, 128);
    height: auto;
    color: white;
    font-size: 12px;
    justify-content: center;
    z-index: 1;
  }
  
  .mapasitio{
    display: none;
    width: 90%;
    margin: 5% 20% 5% 5%;
  }

  .forma{
    width: 90%;
    margin: 5% 5% 5% 5%;
  }

  .contacto{
    width: 80%;
    font-size: 1rem;
    margin: 5% 5% 5% 5%;
  }

  .contacto h3{
    text-align: center;
  }

  .menu{
    padding: 0;
  }

  .enlacepie{
    font-size: 1rem;
  }

  .direccion{
    font-size: 1rem;
    justify-content: space-between;
  }

  .botonrs{
    font-size: 2rem;
  }

  .lista{
    width: 80px;
    justify-content: space-between;
    z-index:1;
  }

  .ulcont{
    width: 90%;
  }
}

@media screen and (min-width: 501px) and (max-width: 960px) {
  .contenedor{
    background-size: cover;
    font-size: 1rem;
    height: 400px;
  }

  .cabecera{
    justify-content: center;
    vertical-align: middle;
  }
  
  .tituloh1 h1 {
    color: white;
    width: 100%;
    font-size: 2rem;
    font-weight: bolder;
    text-align: center;
    user-select: none;
  }

  .tituloh2 h2 {
    color: white;
    width: 100%;
    padding: 20px 0 20px 0;
    font-size: 1.5rem;
    text-align: center;
    user-select: none;
  }

  .boton{
    width: 180px;
    height: 30px;
    color: lightgray;
    font-size: 12px;
    cursor: pointer;
    user-select: none;
    align-items: center;
  }

  .titulo {
    font-size: 14px;
  }

  .seccionultima{
    display: flex;
    flex-direction: row;
    background-color: rgba(8, 12, 128);
    height: auto;
    color: white;
    font-size: 12px;
    z-index: 1;
  }
  
  .mapasitio{
    display: none;
    width: 90%;
    margin: 5% 20% 5% 5%;
  }

  .forma{
    width: 50%;
    margin: 5% 5% 5% 5%;
  }

  .contacto{
    width: 50%;
    font-size: 1rem;
    margin: 5% 5% 5% 5%;
  }
  
  .ulcont{
    width: 90%;
  }
}