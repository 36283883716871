input{
    background-color: transparent;
    border: none;
    color: rgba(8, 12, 128);
    border-bottom: 1px solid #9e9e9e;
    border-radius: 0;
    outline: none;
    height: 2rem;
    width: 100%;
    font-size: 16px;
    color: white;
    margin: 0 0 8px 0;
    padding: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-transition: border .3s, -webkit-box-shadow .3s;
    transition: border .3s, -webkit-box-shadow .3s;
    transition: box-shadow .3s, border .3s;
    transition: box-shadow .3s, border .3s, -webkit-box-shadow .3s;
}

textarea{
    background-color: transparent;
    border: none;
    color: rgba(8, 12, 128);
    border-bottom: 1px solid #9e9e9e;
    border-radius: 0;
    outline: none;
    height: 3rem;
    width: 100%;
    color: white;
    font-size: 16px;
    margin: 0 0 8px 0;
    padding: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-transition: border .3s, -webkit-box-shadow .3s;
    transition: border .3s, -webkit-box-shadow .3s;
    transition: box-shadow .3s, border .3s;
    transition: box-shadow .3s, border .3s, -webkit-box-shadow .3s;
}

.forma{
    background-color: rgba(8, 12, 128);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    margin: auto;
    color: lightgray;
    width: 100%;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.forma h4{
    text-align: center;
}

.botonforma{
    width: 100%;
    height: 2rem;
    font-size: 16px;
    background-color: rgb(144, 51, 51);
    color: white;
    border: none;
    cursor: pointer;
}

.linea{
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
}

.inputfields6 {
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto;
    float: left;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 0.75rem;
    min-height: 1px;
    position: relative;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.acontacto{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dcontacto{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.forma h3{
    font-size: 1rem;
    text-align: center;
    color: white;
}

.ulcont{
    width: 90%;
    padding: 10px;
    align-items: center;
}

.ulcont li{
    list-style: none;
}


.direccion{
    color: rgba(8, 12, 128);
    font-weight: normal;
    font-size: medium;
}

.enlacepie{
    text-decoration: none;
    color: rgba(8, 12, 128);
    font-weight: normal;
    font-size: medium;
  }

.cabecera{
    background-image: linear-gradient(to right, rgba(8, 12, 128, 0.9) 0, rgba(24, 111, 241, 0.6) 50%, rgba(8, 12, 128, 0.2) 100%), url(./images/fondocabecera.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    background-color: rgba(8, 12, 128);
    color: white;
    height: 250px;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.tituloh1{
    position: relative;
    text-align: center;
    color: white;
    top: 15%;
    font-size: 2rem;
}

.gmapcontacto{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 2% 5% 2% 5%;
}

.gmap{
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: center;
    overflow: hidden;
}

.dcontacto h3{
    display: flex;
    color: rgba(8, 12, 128);;
    align-items:center;
    justify-content: space-around;
    height: 40px;
    margin: 0;
}

@media screen and (max-width: 500px) {
    .gmapcontacto{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap-reverse;
        margin: 2% 5% 2% 5%;
        justify-content: center;
    }

    .cabecera {
        height: 150px;
    }

    .forma form{
        width: 90%;
    }

    .forma h3{
        width: 90%;
        margin: 0;
    }

    .gmap{
        width: 100%;
        overflow: hidden;
    }
}

@media screen and (min-width: 501px) and (max-width: 1250px) {
    .gmapcontacto{
        display: flex;
        flex-direction:column-reverse;
        flex-wrap: wrap;
        margin: 2% 5% 2% 5%;
        align-items: center;
    }

    .cabecera {
        height: 150px;
    }

    .forma h3{
        margin: 0;
    }

    .gmap{
        overflow: hidden;
    }
}