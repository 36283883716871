.serviciosTitulo {
    color: rgb(1, 5, 100);
    font-size: 2rem;
    text-align: left;
    font-weight: bold;
    user-select: none;
}

.serviciosTitulo h3 {
    display: flex;
    width: auto;
    font-weight: bold;
    padding-left: 5%;
    margin: 0%;
}

.serviciosLista {
    list-style: none;
    color: rgb(1, 5, 100);
    font-size: 1rem;
    border-radius: 0px;
    border-style:ridge;
    user-select: none;
}

.servicioImage{
    border-radius: 0px;
}

.auditoria {
    background-image: url(./images/auditoria.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    width: 300px;
    height: 250px;
    max-width: 100%;
    max-height: auto;
    align-items: center;
    justify-content: center;
    font-weight: bolder;
    user-select: none;
}

/*.auditoria:hover{
    cursor: pointer;
    background-image: linear-gradient(to top, rgba(8, 12, 128, 0.7) 0, rgba(24, 111, 241, 0.9) 50%, rgba(8, 12, 128, 0.7) 100%), url("images/auditoria.jpg");
    color: #ffffff;
}*/

.contabilidad {
    display: flex;
    background-image: url("images/contabilidad.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    width: 300px;
    height: 250px;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    user-select: none;
}

.societario {
    display: flex;
    background-image: url("images/societario.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    width: 300px;
    height: 250px;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    user-select: none;
}

/*.contabilidad:hover{
    cursor: pointer;
    background-image: linear-gradient(to bottom, rgba(8, 12, 128, 0.7) 0, rgba(24, 111, 241, 0.9) 50%, rgba(8, 12, 128, 0.7) 100%), url("./contabilidad.png");
    color: white;
}*/

.laboral {
    display: flex;
    background-image: url("./images/laboral.jpg");
    background-size: cover;
    width: 300px;
    height: 250px;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    user-select: none;
}

/*.finanzas:hover{
    cursor: pointer;
    background-image: linear-gradient(to bottom, rgba(8, 12, 128, 0.7) 0, rgba(24, 111, 241, 0.9) 50%, rgba(8, 12, 128, 0.7) 100%), url("./finanzas.png");
    color: white;
}*/

.tributacion {
    display: flex;
    background-image: url("./images/tributacion.jpg");
    background-size:cover;
    width: 300px;
    height: 250px;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    user-select: none;
}

/*.tributacion:hover{
    cursor: pointer;
    background-image: linear-gradient(to bottom, rgba(8, 12, 128, 0.7) 0, rgba(24, 111, 241, 0.9) 50%, rgba(8, 12, 128, 0.7) 100%), url("./tributacion.png");
    color: white;
}*/

.tituloservicio{
    border-radius: 10px;
    font-size: 1.2rem;
    text-align: center;
}

.enlaces {
    text-decoration: none;
    color: rgb(1, 5, 100);
    font-size: 14px;
}

.serviciosGrid{
    margin-left: 5%;
    margin-right: 5%;
    display: grid;
/*    grid-template-columns:repeat(auto-fill, 300px); */
    grid-template-columns:repeat(3, 300px);
    gap: 20px;
    padding: 20px 50px 20px 50px;
    justify-content: center;
    justify-items: center;
}

.boton{
    position: relative;
    text-align: center;
    height: 25px;
    background-color:rgba(121, 2, 2);
    border-style: none;
    color: lightgray;
    font-size: 1rem;
    cursor: pointer;
    user-select: none;
  }

  .tarjeta{
      margin: 2% 2%;
  }

  @media screen and (max-width: 500px) {
    .tarjeta p{
        font-size: 1.1rem;
    }

    .serviciosGrid{
        display: grid;
    /*    grid-template-columns:repeat(auto-fill, 300px); */
        grid-template-columns:repeat(1, 300px);
        gap: 20px;
        padding: 20px 50px 20px 50px;
        justify-content: center;
        justify-items: center;
    }

    .serviciosTitulo h3 {
        text-align: center;
        justify-content: center;
        width: auto;
    }
  }

  @media screen and (min-width: 501px) and (max-width: 640px) {
    .serviciosGrid{
        display: grid;
        grid-template-columns:repeat(1, 300px);
        gap: 20px;
        padding: 20px 50px 20px 50px;
        justify-content: center;
        justify-items: center;
    }

    .serviciosTitulo h3 {
        text-align: center;
        justify-content: center;
        width: auto;
    }
  }

  @media screen and (min-width: 641px) and (max-width: 960px) {
    .serviciosGrid{
        display: grid;
        grid-template-columns:repeat(2, 300px);
        gap: 20px;
        padding: 20px 50px 20px 50px;
        justify-content: center;
        justify-items: center;
    }

    .serviciosTitulo h3 {
        width: auto;
        text-align: center;
        justify-content: center;
    }
  }